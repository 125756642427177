import styled, { css } from 'styled-components';

import { atMinWidth } from '../../../styles/atoms/breakpoints';
import { font } from '../../../styles/atoms/typography';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-flow: column nowrap;
  gap: 16px;

  ${atMinWidth.md`
    gap: 24px;
  `}
`;

export const ReviewsContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: ${props => (props.isCentered ? 'center' : 'flex-start')};
  align-items: center;
  gap: 7px;
  ${font('text', 'sm', 400)}

  svg {
    width: 24px;
    height: 22px;
  }
`;

export const SocialReviews = styled.p`
  ${font('text', 'sm', '400')}
  color: ${props =>
    props.color
      ? props.color
      : css`var(--Gray-600, var(--Color-Gray-gray-600, #6F7283))`};
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: 2px;
  text-transform: uppercase;

  ${atMinWidth.md`
    margin-left: 20px;
    // ${font('text', 'md', '400')}
  `}
`;

export const IconContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  /* justify-content: ${props =>
    props.isCentered ? 'center' : 'space-between'}; */
  align-items: center;
  gap: 12px;
  width: 80%;
  justify-content: space-between;

  ${atMinWidth.sm`
    gap: 24px;
  `}

  ${atMinWidth.md`
    gap: 36px;
    // justify-content: ${props =>
      props.isCentered ? 'center' : 'flex-start'};
  `}
`;

export const CompanyIcon = styled.div`
  ${props =>
    props.isWhite &&
    css`
      filter: grayscale(1) brightness(0) invert(1);
    `};
  margin: 0;
  flex: 0 1 auto;

  svg {
    width: 100%;
    max-height: 40px;
  }
`;
