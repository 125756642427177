import React from 'react';

import { CompanyIcon } from '../styles/HeroSocialProof.styles';

import Icon from '../../../styles/atoms/icons';

const domainRegEx = /^(?:https?:\/\/)?(?:[^@\/\n]+@)??([^:\/?\n]+)/i;

const HeroBadge = ({ href, src, isWhite, viewBox, children }) => {
  return (
    <CompanyIcon isWhite={isWhite}>
      <a
        target="_blank"
        rel="noreferrer"
        href={href}
        aria-label={href.match(domainRegEx)[1]}
      >
        {children ? children : <Icon id={src} viewBox={viewBox} isImage />}
      </a>
    </CompanyIcon>
  );
};

export default HeroBadge;
